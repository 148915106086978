import * as AuthApi from '@/api'
import responseStatuses from '@/constants/response-statuses'

const is2FaRequired = status => status === responseStatuses.processed

const AuthService = {
  async login (payload) {
    const { data, status } = await AuthApi.login(payload)
    return { ...data, requires2Fa: is2FaRequired(status) }
  },

  async register (payload) {
    const { data } = await AuthApi.register(payload)
    return data
  },

  async sendConfirmCode (payload) {
    const { data } = await AuthApi.sendConfirmCode(payload)
    return data
  },

  async sendPhoneNumber (payload) {
    const { data } = await AuthApi.sendPhoneNumber(payload)
    return data
  },

  async resetPassword (payload) {
    const { data, status } = await AuthApi.resetPassword(payload)

    return { ...data, requires2Fa: is2FaRequired(status) }
  },

  async sendRecoverConfirmCode (payload) {
    const { data } = await AuthApi.sendRecoverConfirmCode(payload)
    return data
  },

  async getConnected2FaMethods (payload) {
    const { data } = await AuthApi.getConnected2FaMethods(payload)
    return data
  },

  async connect2Fa (payload) {
    const { data } = await AuthApi.connect2Fa(payload)
    return data
  },

  async verify2Fa (payload) {
    const { data } = await AuthApi.verify2Fa(payload)
    return data
  },

  async sendEvent (hit) {
    const today = new Date()
    const datetime = today.toISOString()
    const event = [{
      event_name: hit.name,
      product: hit.product,
      screen_id: hit.screen,
      time: datetime
    }]
    const res = await AuthApi.sendEvent(event)
    return res
  }
}

export default {
  install (Vue) {
    Vue.prototype.$authService = Vue.observable(AuthService)
  }
}
