
import AuthApi from '@/api/routes'
import axios from '@/plugins/axios'
import { getXwToken } from '@/utils/system'
import { getRootUrl } from '@/utils/environment'

const {
  Login,
  Register,
  Recover,
  SendConfirmCode,
  SendPhoneNumber,
  SendRecoverConfirmCode,
  Connected2FaMethods,
  Connect2Fa,
  Verify2Fa
} = AuthApi

const authHeader = { 'X-XWay-Auth': getXwToken() }

export const login = payload => axios.post(Login, payload)

export const register = payload => axios.post(Register, payload)

export const sendConfirmCode = payload => axios.post(SendConfirmCode, payload)

export const sendRecoverConfirmCode = payload => axios.post(SendRecoverConfirmCode, payload)

export const sendPhoneNumber = payload => axios.post(SendPhoneNumber, payload)

export const resetPassword = payload => axios.post(Recover, payload)

export const getConnected2FaMethods = () => axios.get(Connected2FaMethods, { headers: authHeader })

export const connect2Fa = payload => {
  return axios.post(Connect2Fa, payload, { headers: authHeader })
}

export const verify2Fa = payload => {
  return axios.post(Verify2Fa, payload, { headers: authHeader })
}

export const sendEvent = payload => axios.post(`${getRootUrl()}/api/v1/product_analytics/hits`, payload)
