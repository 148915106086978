const Base = '/api'
const AuthBase = `${Base}/auth`
const RegisterBase = `${Base}/register`
const RecoverBase = `${Base}/recover`
const TwoFaBase = `${Base}/2fa`

export default {
  Login: `${AuthBase}/basic`,
  Register: `${RegisterBase}/basic`,
  SendConfirmCode: `${RegisterBase}/validate`,
  SendPhoneNumber: `${RegisterBase}/send-phone`,
  SendRecoverConfirmCode: `${RecoverBase}/validate`,
  Recover: `${RecoverBase}/basic`,
  Connected2FaMethods: `${TwoFaBase}/auth_methods`,
  Connect2Fa: `${TwoFaBase}/auth_methods`,
  Verify2Fa: `${TwoFaBase}/auth_methods/verify`
}
