const cabinetTypes = Object.freeze({
  default: 'default',
  seller: 'seller',
  promo: 'promo',
  ozon2ali: 'ozon2ali',
  social: 'social',
  supplier: 'supplier',
  'beta-seller': 'beta_seller',
  lockobank: 'lockobank'
})

export default cabinetTypes

const {
  VUE_APP_SELLER_CABINET_URL,
  VUE_APP_OZON2ALI_URL
} = process.env

export const defaultCabinetTypes = Object.freeze({
  [VUE_APP_SELLER_CABINET_URL]: cabinetTypes.seller,
  // [VUE_APP_SOCIAL_URL]: cabinetTypes.social,
  [VUE_APP_OZON2ALI_URL]: cabinetTypes.ozon2ali
})
