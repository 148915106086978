import cabinetTypes, { defaultCabinetTypes } from '@/constants/cabinet-types'
import { WEEK_IN_MS } from '@/constants/timings'
import { setCookie, getCookie } from '@/utils/cookies'
import addMsToCurrentTime from '@/utils/addMsToCurrentTime'

const RETURN_URL_STORAGE_ID = 'return_url'
const CABINET_TYPE_STORAGE_ID = 'cabinet_type'

const DEFAULT_RETURN_URL = `${process.env.VUE_APP_SELLER_CABINET_URL}/onboarding/products/all`

export const getReturnUrl = () => {
  return JSON.parse(localStorage.getItem(RETURN_URL_STORAGE_ID)) || DEFAULT_RETURN_URL
}

export const setReturnUrl = (url) => {
  return localStorage.setItem(RETURN_URL_STORAGE_ID, JSON.stringify(url))
}

export const clearReturnUrl = () => {
  localStorage.removeItem(RETURN_URL_STORAGE_ID)
}

/* редиректим обратно туда откуда пришел */
export const redirectToReturnUrl = (token) => {
  setXwToken(token)
  const returnUrl = getReturnUrl()
  clearReturnUrl()

  window.location.href = returnUrl
}

export const setXwToken = (token) => {
  setCookie('xw_token', token, {
    expires: addMsToCurrentTime(WEEK_IN_MS),
    domain: process.env.VUE_APP_ROOT_DOMAIN
  })
}

export const getXwToken = () => getCookie('xw_token')

export const getCabinetType = () => {
  const urlFromStorage = JSON.parse(localStorage.getItem(RETURN_URL_STORAGE_ID))

  if (urlFromStorage === `${process.env.VUE_APP_SELLER_CABINET_URL}/beta-test`) {
    return cabinetTypes['beta-seller']
  } else {
    const returnUrl = new URL(getReturnUrl())
    const currentProjectType = defaultCabinetTypes[returnUrl.origin]
    return localStorage.getItem(CABINET_TYPE_STORAGE_ID) || currentProjectType || cabinetTypes.default
  }
}

export const setCabinetType = (type) => {
  if (!cabinetTypes[type]) return
  localStorage.setItem(CABINET_TYPE_STORAGE_ID, cabinetTypes[type])
}

export const removeCabinetType = () => localStorage.removeItem(CABINET_TYPE_STORAGE_ID)
