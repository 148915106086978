import $ from 'jquery'

function insertGtm (w, d, s, l, i) {
  w[l] = w[l] || []; w[l].push({
    'gtm.start':
    new Date().getTime(),
    event: 'gtm.js'
  }); var f = d.getElementsByTagName(s)[0]
  var j = d.createElement(s); var dl = l !== 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
  'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f)
}

export function init () {
  window.$ = $
  insertGtm(window, document, 'script', 'dataLayer', 'GTM-K6L3W7K')
  window.dataLayer = window.dataLayer || []
}

export function isInitialized () {
  return Boolean(Array.isArray(window.dataLayer))
}

export function pushDataLayerObject (eventObject) {
  if (isInitialized()) {
    window.dataLayer.push(eventObject)
  }
}
