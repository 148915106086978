<template>
  <div class="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  name: 'App',

  computed: {
    layout () {
      return `${this.$route.meta.layout || 'default'}-layout`
    }
  }
}
</script>

<style lang="stylus">
  *
    margin 0
    padding 0

  html,
  body,
  .app
    height 100%
    font-family $font-family

  .app
    background-color #f6f6f9
    font-size: $font-sizes.text
</style>
