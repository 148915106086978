import { init as initGtm, isInitialized as isGTMInitialized } from './gtm'
import { init as initCarrot, isInitialized as isCarrotInitialized } from './carrotquest'

export function initMetrics () {
  initGtm()
  initCarrot()
}

export function isAllMetricsInitialized () {
  return isGTMInitialized() && isCarrotInitialized()
}
