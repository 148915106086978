import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '@/views/Auth.vue'
import { setReturnUrl, clearReturnUrl, setCabinetType } from '@/utils/system'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Auth,
    children: [
      {
        path: '',
        name: 'auth',
        component: () => import(/* webpackChunkName: "auth" */ '../views/SignIn.vue'),
        meta: {
          metrics: {
            screen: 'mmp_login'
          },
          metricsEvents: {
            visit: 'mmp_login_visit',
            login: 'mmp_login_login',
            reg: 'mmp_login_signin',
            forgotPassword: 'mmp_login_reset'
          }
        }
      },
      // {
      //   path: '/sign-up',
      //   name: 'auth.sign.up',
      //   component: () => import(/* webpackChunkName: "sign-up" */ '../views/SignUp.vue'),
      //   meta: {
      //     metrics: {
      //       screen: 'mmp_signin'
      //     },

      //     metricsEvents: {
      //       getRegistrationCode: 'mmp_signin_getcode'
      //     }
      //   }
      // },
      // {
      //   path: '/verify',
      //   name: 'auth.verify',
      //   component: () => import(/* webpackChunkName: "verify"  */ '../views/Verify.vue'),
      //   meta: {
      //     metrics: {
      //       screen: 'mmp_signin'
      //     },

      //     metricsEvents: {
      //       enterCode: 'mmp_signin_entercode'
      //     }
      //   }
      // },
      // {
      //   path: '/send-phone',
      //   name: 'auth.sendphone',
      //   component: () => import(/* webpackChunkName: "send-phone"  */ '../views/SendPhone.vue'),
      //   meta: {
      //     metrics: {
      //       screen: 'mmp_signin'
      //     },
      //     metricsEvents: {
      //       signInPhone: 'mmp_signin_phone'
      //     }
      //   }
      // },
      {
        path: '/reset',
        name: 'auth.recovery',
        component: () => import(/* webpackChunkName: "reset-password"  */ '../views/ResetPassword.vue'),
        meta: {
          metrics: {
            screen: 'mmp_login'
          },

          metricsEvents: {
            enterCode: 'mmp_login_reset_entercode',
            getConfirmCode: 'mmp_login_reset_code'
          }
        }
      }
      // {
      //   path: '/2fa',
      //   component: () => import(/* webpackChunkName: "2fa"  */ '../views/2fa/Index.vue'),
      //   children: [{
      //     path: '',
      //     name: 'auth.2fa.manage',
      //     component: () => import(/* webpackChunkName: "2fa.manage"  */ '../views/2fa/Manage.vue')
      //   }, {
      //     path: 'add',
      //     name: 'auth.2fa.add',
      //     component: () => import(/* webpackChunkName: "2fa.add"  */ '../views/2fa/Add.vue')
      //   }]
      // }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.onReady(to => {
  const {
    return_url: returnUrl,
    cabinet_type: cabinetType
  } = to.query

  if (cabinetType) {
    setCabinetType(cabinetType)
  }

  if (returnUrl) {
    setReturnUrl(returnUrl)
  } else {
    clearReturnUrl()
  }
})

export default router
