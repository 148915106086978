function insertCarrot () {
  function t (t, e) {
    return function () { window.carrotquestasync.push(t, arguments) }
  }
  if (typeof carrotquest === 'undefined') {
    var e = document.createElement('script')
    // eslint-disable-next-line no-unused-expressions,no-sequences,no-undef
    e.type = 'text/javascript', e.async = !0, e.src = '//cdn.carrotquest.app/api.min.js', document.getElementsByTagName('head')[0].appendChild(e), window.carrotquest = {}, window.carrotquestasync = [], carrotquest.settings = {}; for (var n = ['connect', 'track', 'identify', 'auth', 'onReady', 'addCallback', 'removeCallback', 'trackMessageInteraction'], a = 0; a < n.length; a++)carrotquest[n[a]] = t(n[a])
  }
}

export function init () {
  insertCarrot()
  window.carrotquest.connect('48117-d99d5434818b0e266c8afa6be8')
}

export function isInitialized () {
  return Boolean(window.carrotquest)
}

export function auth (userId, carrotQuestUserHash) {
  if (isInitialized()) {
    window.carrotquest.auth(userId, carrotQuestUserHash)
  } else {
    /* eslint-disable no-console */
    console.log('не найден carrotquest')
  }
}
