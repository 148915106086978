import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import Default from '@/layouts/Default'
import '@xway-team/ui/dist/index.css'
import { registerComponents } from '@xway-team/ui'
import '@/plugins/v-mask'
import '@/plugins/vee-validate'
import '@/plugins/vue-toastification'
import AuthService from '@/plugins/auth-service'
import { initMetrics } from '@/metrics'
import { XMetricsPlugin } from '@xway-team/metrics'
import {
  applyTheme,
  clearTheme,
  detectThemeByQuery,
  detectThemeByCookie,
  getCompanyDetails,
  getDefaultCompanyDetails,
  getAvailableThemeNames
} from '@xway-team/theme-detector'
import { setCompanyDetails } from '@/plugins/companyDetails'

const themeName = detectThemeByQuery() || detectThemeByCookie()

if (getAvailableThemeNames().includes(themeName)) {
  applyTheme(themeName)
  setCompanyDetails(getCompanyDetails(themeName))
} else {
  clearTheme()
  setCompanyDetails(getDefaultCompanyDetails())
}

if (process.env.VUE_APP_DEPLOYMENT_ENV === 'production') {
  initMetrics()
}

Vue.config.productionTip = false

Vue.component('default-layout', Default)

Vue.use(AuthService)

registerComponents(Vue)

Vue.use(XMetricsPlugin, {
  product: 'MMP',
  trackFirstVisit: false,
  router,
  useProd: process.env.VUE_APP_DEPLOYMENT_ENV === 'production'
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
