<template>
  <div class="page">
    <header class="page__header">
      <img
        :src="thumbnail"
        class="page__logo"
      >
      <h1 class="page__tagline">
        {{ companyDetails.name }}. Единый путь на все маркетплейсы
      </h1>
    </header>

    <div class="page__main">
      <slot />
    </div>

    <footer class="page__footer">
      <div class="page__copyright">
        &copy;&nbsp;{{ companyDetails.name }} {{ currentYear }}
      </div>
    </footer>
  </div>
</template>

<script>
import companyDetails from '@/plugins/companyDetails'

export default {
  data: () => ({
    companyDetails
  }),

  computed: {
    thumbnail () {
      return require('@/assets/xway-logo.svg')
    },

    currentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .page
    display flex
    flex-direction column
    justify-content space-between
    align-items center
    height 100%
    padding 10px 15px 40px
    box-sizing border-box

    &__header
      margin-bottom 20px
      text-align center

    &__logo
      margin-bottom 5px

    &__main
      flex-grow 1
      width 100%

    &__tagline
      font-size 18px
      font-weight 600
      color #cacad3

    &__copyright
      font-size 14px
      text-align center
      color #cacad3
      margin-bottom 20px
</style>
