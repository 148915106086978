import Vue from 'vue'
import {
  required,
  email,
  min,
  confirmed
} from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'

const phone = {
  message: 'Непохоже на телефон',
  validate (value, args) {
    const phoneRegExp = /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/gm
    return phoneRegExp.test(value)
  }
}

extend('phone', phone)

extend('required', {
  ...required,
  message: 'Поле "{_field_}" обязательно'
})

extend('email', {
  ...email,
  message: 'Непохоже на email'
})

extend('confirmed', {
  ...confirmed,
  message: 'Пароли не совпадают'
})

extend('min', {
  ...min,
  message: 'Поле "{_field_}" должно содержать как минимум {length} символов'
})

extend('digits_length', {
  message: (field, args) => `Поле "${field}" должно содержать ${args[0]} чисел`,
  validate (value, args) {
    const [len] = args
    return value.match(/\d/g).length === +len
  }
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
