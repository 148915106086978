import Vue from 'vue'
import axios from 'axios'

function isNetworkError (err) {
  return !!err.isAxiosError && !err.response
}

const _axios = axios.create({
  baseURL: process.env.VUE_APP_API
})

_axios.interceptors.request.use(
  function (config) {
    return config
  },

  function (error) {
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  function (response) {
    return response
  },

  function (error) {
    if (isNetworkError(error)) {
      Vue.$toast.error('Ошибка сети')
      return
    }

    if (error.response) {
      Vue.$toast.error(error.response?.data?.detail)
    }

    return Promise.reject(error)
  }
)

export default _axios
