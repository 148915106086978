<template>
  <x-card :class="cardClasses">
    <router-view />
  </x-card>
</template>
<script>
export default {
  name: 'auth',
  data () {
    return {
      onboardingPages: ['auth.sign.up', 'auth.verify', 'auth.sendphone']
    }
  },
  computed: {
    cardClasses () {
      const classes = ['auth']
      if (this.onboardingPages.includes(this.$route.name)) {
        classes.push('auth--big-width')
      }
      return classes
    }
  }
}
</script>
<style lang="stylus" scoped>
  .auth
    width 100%
    max-width 540px
    margin 0 auto
    &--big-width
      padding 0
      max-width 1280px
</style>
