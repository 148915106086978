import Vue from 'vue'

const companyDetails = Vue.observable({
  name: '',
  platformName: ''
})

export function setCompanyDetails (newCompanyDetails = {}) {
  Object.keys(newCompanyDetails).forEach(key => {
    companyDetails[key] = newCompanyDetails[key]
  })
}

export default companyDetails
